import React from "react";
import { useTranslation } from 'react-i18next';

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import Header from "../../../data/images/titta-klocka.jpg";
import TydligKalender from "../../../data/images/products/tydlig_kalender.png";
import Wellbee from "../../../data/images/products/wellbee.png";
import TalKomm from "../../../data/images/products/Tal Komm/tal-komm_pad.png";
import MEDtime from "../../../data/images/products/MED Time/med_time.png";
import Valter from "../../../data/images/products/Valter/valter.png";
import PortKomm from "../../../data/images/products/Port Komm/portkommtilsammans.png";
import TryggVardagBild from "../../../data/images/products/Trygg Vardag/Tryggvardag_huvudbild.png";


/* ---------------------------------------------------------------------------- */

import { HiArrowNarrowRight } from "react-icons/hi";

/* ---------------------------------------------------------------------------- */

import {
  ProductsArticle,
  ProductsArticleDescription,
  ProductsArticleImage,
  ProductsArticles,
  ProductsDescription,
  ProductsHeader,
  ProductsHeaderImage,
  ProductsHeaderText,
  ProductsImage,
  ProductsLink,
  ProductsSection,
} from "./StyledComponents";

/* ============================================================================ */
/*       COMMON PRODUCTS - Function
/* ============================================================================ */

const CommonProducts = () => {  
  const { t } = useTranslation("products"); 

  return (
    <main className="products container">
      <ProductsHeader>
        <ProductsHeaderText>
          <h1>{t('h1_1_products')}</h1>
          <h3>{t('h3_1_products')}</h3>
        </ProductsHeaderText>
        <ProductsHeaderImage src={Header} alt="" />
      </ProductsHeader>

      <ProductsSection>
        <ProductsDescription>
          <p>{t('p1_products')}</p>
          <p>{t('p2_products')}</p>

          <ProductsArticles>
            <ProductsArticle>
              <ProductsArticleImage>
                <ProductsImage src={TydligKalender} alt="" />
              </ProductsArticleImage>

              <ProductsArticleDescription>
                <h1>{t('h1_2_products')}</h1>
                <p>{t('p3_products')}</p>
                <p>{t('p4_products')}
                <br />
                  <a
                    href="https://www.tydligkalender.nu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.tydligkalender.nu
                  </a>
                  <ProductsLink to="/produkter/tydlig-kalender-basic">
                    <HiArrowNarrowRight />
                    <span>{t('link_1_tydligkalender')} <b>Basic</b></span>
                  </ProductsLink>
                  <ProductsLink to="/produkter/tydlig-kalender-pro">
                    <HiArrowNarrowRight />
                    <span>{t('link_2_tydligkalender')} <b>Pro</b></span>
                  </ProductsLink>
                  <ProductsLink to="/produkter/tydlig-kalender-klocka">
                    <HiArrowNarrowRight />
                    <span>{t('link_3_tydligkalender')} <b>Klocka</b></span>
                  </ProductsLink>
                </p>
              </ProductsArticleDescription>

              <ProductsArticleImage>
                <ProductsImage src={TalKomm} alt="" />
              </ProductsArticleImage>

              <ProductsArticleDescription>
                <h1>{t('h1_3_products')}</h1>
                <p>{t('p5_products')}
                  <ProductsLink to="/produkter/tal-komm">
                    <HiArrowNarrowRight />
                    <span>{t('link_1_talkomm')} <b>tal | komm</b></span>
                  </ProductsLink>
                </p>
              </ProductsArticleDescription>

              <ProductsArticleImage>
                <ProductsImage src={PortKomm} alt="" />
              </ProductsArticleImage>

              <ProductsArticleDescription>
                <h1>{t('h1_7_products')}</h1>
                <p>{t('p10_products')}
                  <ProductsLink to="/produkter/port-komm">
                    <HiArrowNarrowRight />
                    <span>{t('link_1_portkomm')} <b>port | komm</b></span>
                  </ProductsLink>
                </p>
              </ProductsArticleDescription>
              
              <ProductsArticleImage>
                <ProductsImage src={MEDtime} alt="" />
              </ProductsArticleImage>

              <ProductsArticleDescription>
                <h1>{t('h1_5_products')}</h1>
                <p>{t('p8_products')}
                  <ProductsLink to="/produkter/med-time">
                    <HiArrowNarrowRight />
                    <span>{t('link_1_medtime')} <b>med | time</b></span>
                  </ProductsLink>
                </p>
              </ProductsArticleDescription>

              <ProductsArticleImage>
                <ProductsImage src={Wellbee} alt="" />
              </ProductsArticleImage>

              <ProductsArticleDescription>
                <h1>{t('h1_4_products')}</h1>
                <p>{t('p6_products')}</p>
                <p>{t('p7_products')}
                  <ProductsLink to="/produkter/wellbee-app">
                    <HiArrowNarrowRight />
                    <span>{t('link_1_wellbee')} <b>Wellbee APP</b></span>
                  </ProductsLink>
                  <ProductsLink to="/produkter/wellbee-pad">
                    <HiArrowNarrowRight />
                    <span>{t('link_2_wellbee')} <b>Wellbee PAD</b></span>
                  </ProductsLink>
                  <ProductsLink to="/produkter/wellbee-wall">
                    <HiArrowNarrowRight />
                    <span>{t('link_3_wellbee')} <b>Wellbee WALL</b></span>
                  </ProductsLink>
                </p>
              </ProductsArticleDescription>

              <ProductsArticleImage>
                <ProductsImage src={Valter} alt="" />
              </ProductsArticleImage>

              <ProductsArticleDescription>
                <h1>{t('h1_6_products')}</h1>
                <p>{t('p9_products')}
                  <ProductsLink to="/produkter/valter">
                    <HiArrowNarrowRight />
                    <span>{t('link_1_valter')} <b>Valter</b></span>
                  </ProductsLink>
                </p>
              </ProductsArticleDescription>

              <ProductsArticleImage>
                <ProductsImage src={TryggVardagBild} alt="" />
              </ProductsArticleImage>

              <ProductsArticleDescription>
                <h1>{t('h1_8_products')}</h1>
                <p>{t('p11_products')}</p>
                <p>{t('p12_products')}</p>
                <p>{t('p13_products')}
                <br />
                  <a
                    href="https://www.tryggvardag.se"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.tryggvardag.se
                  </a>
                  <ProductsLink to="/produkter/tryggvardag">
                    <HiArrowNarrowRight />
                    <span>{t('link_1_tydligkalender')} <b>Trygg vardag</b></span>
                  </ProductsLink>
                  <ProductsLink to="/produkter/tryggvardagbasic">
                    <HiArrowNarrowRight />
                    <span>{t('link_2_tydligkalender')} <b>Basic</b></span>
                  </ProductsLink>
                  <ProductsLink to="/produkter/tryggvardagklocka">
                    <HiArrowNarrowRight />
                    <span>{t('link_3_tydligkalender')} <b>Klocka</b></span>
                  </ProductsLink>
                  <ProductsLink to="/produkter/tryggvardagsurfplatta">
                    <HiArrowNarrowRight />
                    <span>{t('link_3_tydligkalender')} <b>Surfplatta</b></span>
                  </ProductsLink>
                  <ProductsLink to="/produkter/tryggvardagtavla">
                    <HiArrowNarrowRight />
                    <span>{t('link_3_tydligkalender')} <b>Tavla</b></span>
                  </ProductsLink>

                </p>
              </ProductsArticleDescription>

            </ProductsArticle>
          </ProductsArticles>
        </ProductsDescription>
      </ProductsSection>
    </main>
  );
};

export default CommonProducts;
