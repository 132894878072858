
import React from "react";
import ProductInfo from "./ProductInfo";
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from 'react-i18next';

/* ---------------------------------------------------------------------------- */

import {
  DownloadButton,
  DownloadList,
  ProductDescription,
  ProductDocument,
  ProductImage,
  ProductSection,
} from "./StyledComponents";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import TryggVardagSurfplattaBild from "../../../../data/images/products/Trygg Vardag Surfplatta/Huvudbild_surfplatta.png";


/* ----------------------------------------------------------------------------
 *          DOWNLOAD FILES
 * --------------------------------------------------------------------------- */

import TryggVardagSurfplattaManual from "../../../../data/downloads/Manual-trygg-vardag-surfplatta.pdf";
import TryggVardagSurfplattaStartaUpp from "../../../../data/downloads/Starta-upp-trygg-vardag-surfplattan.pdf";
import TryggVardagRekond from "../../../../data/downloads/Trygg_vardag_rekonditionering.pdf";


/* ============================================================================ */
/*          PORT | KOMM - function
/* ============================================================================ */


const TryggVardagSurfplatta = () => {
  
  const { t } = useTranslation("products/tryggvardagsurfplatta"); 

  return (
    <main className="product container">
      <ProductSection>
        <h1>{t('h1_tryggvardagsurfplatta')}</h1>
        <ProductImage src={TryggVardagSurfplattaBild} alt="" />

        <ProductDescription>
          <p>{t('p1_tryggvardagsurfplatta')}</p>
          <p>{t('p2_tryggvardagsurfplatta')}</p>
        </ProductDescription>
    
        <ProductInfo titel={t('dokument_tryggvardagsurfplatta')}>
          <ProductDocument>
            <ul>
              <p>{t('laddaner_tryggvardagsurfplatta')}</p>

              <DownloadList>
                <DownloadButton>
                  <a href={TryggVardagSurfplattaManual} download="manual-trygg-vardag-surfplatta.pdf">
                    <span>Användarmanual</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>                
                <DownloadButton>
                  <a href={TryggVardagSurfplattaStartaUpp} download="Starta-upp-trygg-vardag-surfplatta.pdf">
                    <span>Starta upp Manual</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
                <DownloadButton>
                  <a href={TryggVardagRekond} download="manual-trygg-rekonditionering.pdf">
                    <span>Rekonditionersingsmanual</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>
            </ul>
          </ProductDocument>
        </ProductInfo>
        
      </ProductSection>
    </main>
  );
};

export default TryggVardagSurfplatta;
