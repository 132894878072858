import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider, Outlet, createBrowserRouter } from "react-router-dom";
import ScrollToTop from "./routes/ScrollToTop";

import "./index.css";

/* ----------------------------------------------------------------------------
 *          LANGUAGES
 * --------------------------------------------------------------------------- */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

/* ----------------------------------------------------------------------------
 *          COMPONENTS
 * --------------------------------------------------------------------------- */

import Sidebar from "./components/sidebar/Sidebar";
import Footer from "./components/footer/Footer";

/* ----------------------------------------------------------------------------
 *          ROUTES
 * --------------------------------------------------------------------------- */

import Home from "./routes/home/Home";
import CommonProducts from "./routes/pages/products/CommonProducts";
import Services from "./routes/pages/services/Services";
import About from "./routes/pages/about/About";
import Contact from "./routes/pages/contact/Contact";
import Login from "./routes/pages/login/Login";
import Tekniker from "./routes/pages/tekniker/Tekniker";

/* ----------------------------------------------------------------------------
 *          STATE
 * --------------------------------------------------------------------------- */

import { UserProvider } from '../src/stores/userContext.js'; // Import the UserProvider
import PrivateRoute from "./routes/PrivateRoutes";

/* ---------------------------------------------------------------------------- */

import TydligKalenderBasic from "./routes/pages/products/product/TydligKalenderBasic";
import TydligKalenderPro from "./routes/pages/products/product/TydligKalenderPro";
import TydligKalenderKlocka from "./routes/pages/products/product/TydligKalenderKlocka";
import TalKomm from "./routes/pages/products/product/TalKomm";
import WellbeeApp from "./routes/pages/products/product/WellbeeApp";
import WellbeePad from "./routes/pages/products/product/WellbeePad";
import WellbeeWall from "./routes/pages/products/product/WellbeeWall";
import MEDtime from "./routes/pages/products/product/MEDtime";
import Valter from "./routes/pages/products/product/Valter";
import PortKomm from "./routes/pages/products/product/PortKomm";
import TryggVardag from "./routes/pages/products/product/TryggVardag.jsx";
import TryggVardagBasic from "./routes/pages/products/product/TryggVardagBasic.jsx";
import TryggVardagKlocka from "./routes/pages/products/product/TryggVardagKlocka.jsx";
import TryggVardagSurfplatta from "./routes/pages/products/product/TryggVardagSurfplatta.jsx";
import TryggVardagTavla from "./routes/pages/products/product/TryggVardagTavla.jsx";
import NotFoundPage from "./routes/pages/error/Error";
import Newsletter from "./routes/pages/newsletter/Newsletter.jsx";

/* ============================================================================ */
/*          BR|STRÖM - APP
/* ============================================================================ */


i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['sv', 'en', 'no', 'fi'],
    fallbackLng: 'sv',
    debug: false,
    ns: [
      'home', 
      'about', 
      'about/team',     
      'services', 
      'contact', 
      'products',
      'products/medtime', 
      'products/portkomm',
      'products/talkomm', 
      'products/tkBasic',
      'products/tkKlocka',
      'products/tkPro', 
      'products/valter',
      'products/wellbeeApp',
      'products/wellbeePad',
      'products/wellbeeWall', 
      'products/tryggvardag',
      'products/tryggvardagbasic',
      'products/tryggvardagklocka',
      'products/tryggvardagsurfplatta',
      'products/tryggvardagtavla',       
      'error',   
      'login',
      'tekniker', 
      'sidebar', 
     ],
    backend: {
      loadPath: `/languages/{{lng}}/{{ns}}/translation.json`,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
})


const App = () => (
  
  <>  
    <UserProvider>
      <ScrollToTop />
      <Sidebar />
      <Outlet />
      <Footer /> 
    </UserProvider> 
  </>
 
);

/* ---------------------------------------------------------------------------- */

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "*",
        element: <NotFoundPage />,
      },
      {
        path: "/",
        element: <PrivateRoute component={Home} />,
      },
      {
        path: "/produkter",
        element: <PrivateRoute component={CommonProducts} />,
      },
      {
        path: "/tjanster",
        element: <PrivateRoute component={Services} />,
      },
      {
        path: "/om",
        element: <PrivateRoute component={About} />,
      },
      {
        path: "/kontakt",
        element: <PrivateRoute component={Contact} />,
      },
      {
        path: "/newsletter",
        element: <PrivateRoute component={Newsletter} />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/tekniker",
        element: <PrivateRoute component={Tekniker} />
      },
      // --- Underkategorier till Produkter --- //
      {
        path: "/produkter/tydlig-kalender-basic",
        element: <TydligKalenderBasic />,
      },
      {
        path: "/produkter/tydlig-kalender-pro",
        element: <TydligKalenderPro />,
      },
      {
        path: "/produkter/tydlig-kalender-klocka",
        element: <TydligKalenderKlocka />,
      },
      {
        path: "/produkter/tal-komm",
        element: <TalKomm />,
      },
      {
        path: "/produkter/wellbee-app",
        element: <WellbeeApp />,
      },
      {
        path: "/produkter/wellbee-pad",
        element: <WellbeePad />,
      },
      {
        path: "/produkter/wellbee-wall",
        element: <WellbeeWall />,
      },
      {
        path: "/produkter/med-time",
        element: <MEDtime />,
      },
      {
        path: "/produkter/valter",
        element: <Valter />,
      },
      {
        path: "/produkter/port-komm",
        element: <PortKomm />,
      },
      {
        path: "/produkter/tryggvardag",
        element: <TryggVardag />,
      },
      {
        path: "/produkter/tryggvardagbasic",
        element: <TryggVardagBasic />,
      },
      {
        path: "/produkter/tryggvardagklocka",
        element: <TryggVardagKlocka />,
      },
      {
        path: "/produkter/tryggvardagsurfplatta",
        element: <TryggVardagSurfplatta/>,
      },
      {
        path: "/produkter/tryggvardagtavla",
        element: <TryggVardagTavla />,
      },
    ],
  },
]);

/* ---------------------------------------------------------------------------- */

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

export default router;