
import React from "react";
import ProductInfo from "./ProductInfo";
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from 'react-i18next';

/* ---------------------------------------------------------------------------- */

import {
  DownloadButton,
  DownloadList,
  ProductDescription,
  ProductDocument,
  ProductImage,
  ProductSection,
} from "./StyledComponents";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import TryggVardagTavlaBild from "../../../../data/images/products/Trygg Vardag Tavla/Huvudbild_tavla.png";


/* ----------------------------------------------------------------------------
 *          DOWNLOAD FILES
 * --------------------------------------------------------------------------- */

import TryggVardagTavlaManual from "../../../../data/downloads/Användarmanual-trygg-vardag-tavla.pdf";
import TryggVardagTavlaStartaUpp from "../../../../data/downloads/Starta-upp-trygg-vardag-tavla.pdf";
import TryggVardagTavlaSnabbguide from "../../../../data/downloads/Snabbguide-trygg-vardag-tavla.pdf";
import TryggVardagRekond from "../../../../data/downloads/Trygg_vardag_rekonditionering.pdf";


/* ============================================================================ */
/*          PORT | KOMM - function
/* ============================================================================ */


const TryggVardagTavla = () => {
  
  const { t } = useTranslation("products/tryggvardagtavla"); 

  return (
    <main className="product container">
      <ProductSection>
        <h1>{t('h1_tryggvardagtavla')}</h1>
        <ProductImage src={TryggVardagTavlaBild} alt="" />

        <ProductDescription>
          <p>{t('p1_tryggvardagtavla')}</p>
          <p>{t('p2_tryggvardagtavla')}</p>
        </ProductDescription>
    
        <ProductInfo titel={t('dokument_tryggvardagtavla')}>
          <ProductDocument>
            <ul>
              <p>{t('laddaner_tryggvardagtavla')}</p>

              <DownloadList>
                <DownloadButton>
                  <a href={TryggVardagTavlaManual} download="Användarmanual-trygg-vardag-tavla.pdf">
                    <span>Användarmanual</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>                
                <DownloadButton>
                  <a href={TryggVardagTavlaStartaUpp} download="Starta-upp-trygg-vardag-tavla.pdf">
                    <span>Starta upp Manual</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
                <DownloadButton>
                  <a href={TryggVardagTavlaSnabbguide} download="Snabbguide-trygg-vardag-tavla.pdf">
                    <span>Snabbguide</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
                <DownloadButton>
                  <a href={TryggVardagRekond} download="manual-trygg-rekonditionering.pdf">
                    <span>Rekonditionersingsmanual</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>
            </ul>
          </ProductDocument>
        </ProductInfo>
        
      </ProductSection>
    </main>
  );
};

export default TryggVardagTavla;
