import React from "react";
import ProductInfo from "./ProductInfo";
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from 'react-i18next';

/* ---------------------------------------------------------------------------- */

import {
  DownloadButton,
  DownloadList,
  ProductDescription,
  ProductDocument,
  ProductImage,
  ProductSection,
} from "./StyledComponents";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import TryggVardagBild from "../../../../data/images/products/Trygg Vardag/Tryggvardag_huvudbild.png";


/* ----------------------------------------------------------------------------
 *          DOWNLOAD FILES
 * --------------------------------------------------------------------------- */

import TryggVardagManual from "../../../../data/downloads/Manual-trygg-vardag.pdf";
import TryggVardagStartaUpp from "../../../../data/downloads/Starta_upp_trygg_vardag.pdf";
import TryggVardagRekond from "../../../../data/downloads/Trygg_vardag_rekonditionering.pdf";
import TryggVardagManualPaket1 from "../../../../data/downloads/Användarmanual_trygg_vardag_paket1.pdf";
import TryggVardagManualPaket2 from "../../../../data/downloads/Användarmanual_trygg_vardag_paket2.pdf";


/* ============================================================================ */
/*          PORT | KOMM - function
/* ============================================================================ */


const TryggVardag = () => {
  
  const { t } = useTranslation("products/tryggvardag"); 

  return (
    <main className="product container">
      <ProductSection>
        <h1>{t('h1_tryggvardag')}</h1>
        <ProductImage src={TryggVardagBild} alt="" />

        <ProductDescription>
          <p>{t('p1_tryggvardag')}</p>
          <p>{t('p2_tryggvardag')}</p>
        </ProductDescription>
    
        <ProductInfo titel={t('dokument_tryggvardag')}>
          <ProductDocument>
            <ul>
              <p>{t('laddaner_tryggvardag')}</p>

              <DownloadList>
                <DownloadButton>
                  <a href={TryggVardagManual} download="Manual-trygg-vardag.pdf">
                    <span>Användarmanual</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>                
                <DownloadButton>
                  <a href={TryggVardagStartaUpp} download="Starta_upp_trygg_vardag.pdf">
                    <span>Starta upp Manual</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
                <DownloadButton>
                  <a href={TryggVardagRekond} download="manual_trygg_rekonditionering.pdf">
                    <span>Rekonditionersingsmanual</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>
            </ul>
          </ProductDocument>
        </ProductInfo>
        
        <ProductInfo titel={t('dokument_tryggvardag_paket')}>
          <ProductDocument>
            <ul>
              <p>{t('laddaner_tryggvardag')}</p>

              <DownloadList>
                <DownloadButton>
                  <a href={TryggVardagManualPaket1} download="TryggVardagManualPaket1.pdf">
                    <span>Trygg Vardag Manual Paket 1</span>
                    <MdOutlineFileDownload />
                  </a>
                  </DownloadButton>
                  <DownloadButton>
                  <a href={TryggVardagManualPaket2} download="TryggVardagManualPaket1.pdf">
                    <span>Trygg Vardag Manual Paket 2</span>
                    <MdOutlineFileDownload />
                  </a>
                  </DownloadButton>
                </DownloadList>
            </ul>
          </ProductDocument>
        </ProductInfo>

        
      </ProductSection>
    </main>
  );
};

export default TryggVardag;
